import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { set_record_video_list_state } from "../../slices/videosApp";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

function KzoneViewSwitcher() {
  const dispatch = useAppDispatch();
  // const record = useAppSelector((state) => state.videosAppReducer.record);
  const selected = useAppSelector((state) => state.videosAppReducer.selected);

  const handleVideoList = (file_path) => {
    const endpoint = "wdragons.dscloud.me:5010/api/v1/get_karmazone_video/";
    const videoList = selected.palyer_record.all.map((data) => {
      return { url: `http://${endpoint}${file_path}${data.Clip_ID}.mp4` };
    });
    dispatch(set_record_video_list_state(videoList));
  };

  const handleCam11 = () => {
    const file_path = `${selected.year.label}/${selected.game.label}/${selected.date.label}/Cam-11/${selected.video_folder_state[10].Cam11}`;
    handleVideoList(file_path);
  };

  const handleCam12 = () => {
    const file_path = `${selected.year.label}/${selected.game.label}/${selected.date.label}/Cam-12/${selected.video_folder_state[11].Cam12}`;
    handleVideoList(file_path);
  };

  const handleCam13 = () => {
    const file_path = `${selected.year.label}/${selected.game.label}/${selected.date.label}/Cam-13/${selected.video_folder_state[12].Cam13}`;
    handleVideoList(file_path);
  };

  const handleCam14 = () => {
    const file_path = `${selected.year.label}/${selected.game.label}/${selected.date.label}/Cam-14/${selected.video_folder_state[13].Cam14}`;
    handleVideoList(file_path);
  };

  const handleCam15 = () => {
    const file_path = `${selected.year.label}/${selected.game.label}/${selected.date.label}/Cam-15/${selected.video_folder_state[14].Cam15}`;
    handleVideoList(file_path);
  };

  const handleCam16 = () => {
    const file_path = `${selected.year.label}/${selected.game.label}/${selected.date.label}/Cam-16/${selected.video_folder_state[15].Cam16}`;
    handleVideoList(file_path);
  };

  const handleCam17 = () => {
    const file_path = `${selected.year.label}/${selected.game.label}/${selected.date.label}/Cam-17/${selected.video_folder_state[16].Cam17}`;
    handleVideoList(file_path);
  };

  const handleCam18 = () => {
    const file_path = `${selected.year.label}/${selected.game.label}/${selected.date.label}/Cam-18/${selected.video_folder_state[17].Cam18}`;
    handleVideoList(file_path);
  };

  const handleCam19 = () => {
    const file_path = `${selected.year.label}/${selected.game.label}/${selected.date.label}/Cam-19/${selected.video_folder_state[18].Cam19}`;
    handleVideoList(file_path);
  };

  const handleCam20 = () => {
    const file_path = `${selected.year.label}/${selected.game.label}/${selected.date.label}/Cam-20/${selected.video_folder_state[19].Cam20}`;
    handleVideoList(file_path);
  };

  return (
    <Box m={0.5}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={6}>
          <Button
            onClick={handleCam11}
            disabled={selected.video_folder_state[10].Count === "0"}
            size="large"
            fullWidth
            variant="outlined"
            color="inherit"
          >
            本後上方
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Button
            onClick={handleCam12}
            disabled={selected.video_folder_state[11].Count === "0"}
            size="large"
            fullWidth
            variant="outlined"
            color="inherit"
          >
            本後投打
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Button
            onClick={handleCam13}
            disabled={selected.video_folder_state[12].Count === "0"}
            size="large"
            fullWidth
            variant="outlined"
            color="inherit"
          >
            側面投打
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Button
            onClick={handleCam14}
            disabled={selected.video_folder_state[13].Count === "0"}
            size="large"
            fullWidth
            variant="outlined"
            color="inherit"
          >
            跑者投手
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Button
            onClick={handleCam15}
            disabled={selected.video_folder_state[14].Count === "0"}
            size="large"
            fullWidth
            variant="outlined"
            color="inherit"
          >
            左投出手
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Button
            onClick={handleCam16}
            disabled={selected.video_folder_state[15].Count === "0"}
            size="large"
            fullWidth
            variant="outlined"
            color="inherit"
          >
            右投出手
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Button
            onClick={handleCam17}
            disabled={selected.video_folder_state[16].Count === "0"}
            size="large"
            fullWidth
            variant="outlined"
            color="inherit"
          >
            左打視角
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Button
            onClick={handleCam18}
            disabled={selected.video_folder_state[17].Count === "0"}
            size="large"
            fullWidth
            variant="outlined"
            color="inherit"
          >
            右打視角
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Button
            onClick={handleCam19}
            disabled={selected.video_folder_state[18].Count === "0"}
            size="large"
            fullWidth
            variant="outlined"
            color="inherit"
          >
            一壘跑者
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Button
            onClick={handleCam20}
            disabled={selected.video_folder_state[19].Count === "0"}
            size="large"
            fullWidth
            variant="outlined"
            color="inherit"
          >
            三壘跑者
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

export default KzoneViewSwitcher;
