import { useAppSelector, useAppDispatch } from "../../app/hooks";
import {
  set_record_player_state,
  set_record_player_record_state,
  set_selected_type_state,
  set_selected_player_state,
  set_selected_player_record_state,
} from "../../slices/videosApp";

import Box from "@mui/material/Box";
import Select from "react-select";

function SelectType() {
  const dispatch = useAppDispatch();
  const record = useAppSelector((state) => state.videosAppReducer.record);
  const selected = useAppSelector((state) => state.videosAppReducer.selected);

  const customStyles = {
    option: (provided) => ({
      ...provided,
      color: "black",
    }),
  };

  const playerSelectOptions = (e) => {
    if (record.all_record[selected.team.label] === "Guest") {
      switch (e.label) {
        case "投手":
          const GuestPitcher = record.all_record.GuestPitcher.map(
            (data, index) => {
              return { value: index, label: data.投手 };
            }
          );
          dispatch(set_record_player_state(GuestPitcher));
          break;
        case "打者":
          const GuestBatter = record.all_record.GuestBatter.map(
            (data, index) => {
              return { value: index, label: data.打者 };
            }
          );
          dispatch(set_record_player_state(GuestBatter));
          break;
        default:
          break;
      }
    } else {
      switch (e.label) {
        case "投手":
          const GuestPitcher = record.all_record.HomePitcher.map(
            (data, index) => {
              return { value: index, label: data.投手 };
            }
          );
          dispatch(set_record_player_state(GuestPitcher));
          break;
        case "打者":
          const GuestBatter = record.all_record.HomeBatter.map(
            (data, index) => {
              return { value: index, label: data.打者 };
            }
          );
          dispatch(set_record_player_state(GuestBatter));
          break;
        default:
          break;
      }
    }
  };

  const handleChange = (e) => {
    dispatch(set_record_player_state([]));
    dispatch(set_record_player_record_state([]));

    dispatch(set_selected_player_state(null));
    dispatch(set_selected_player_record_state(null));
    dispatch(set_selected_type_state(e));
    playerSelectOptions(e);
  };

  return (
    <Box m={0.5}>
      <Select
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: "gray",
          },
        })}
        onChange={handleChange}
        // isLoading={record.team === []}
        styles={customStyles}
        value={selected.type}
        options={record.type}
        placeholder="選擇類別"
      />
    </Box>
  );
}

export default SelectType;
