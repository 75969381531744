import { useAppSelector, useAppDispatch } from "../../app/hooks";
import {
  set_selected_type_state,
  set_selected_date_state,
  set_selected_team_state,
  set_selected_player_state,
  set_selected_player_record_state,
  set_selected_video_folder_state,
  set_record_team_state,
  // set_record_type_state,
  set_record_player_state,
  set_record_all_record_state,
  set_record_player_record_state,
} from "../../slices/videosApp";

import axios from "axios";

import Box from "@mui/material/Box";
import Select from "react-select";

function SelectDate() {
  const dispatch = useAppDispatch();
  const record = useAppSelector((state) => state.videosAppReducer.record);
  const selected = useAppSelector((state) => state.videosAppReducer.selected);

  const customStyles = {
    option: (provided) => ({
      ...provided,
      color: "black",
    }),
  };

  const fetchAllRecord = (e) => {
    axios
      .post("http://wdragons.dscloud.me:5010/api/v1/data", {
        date: `${selected.year.label}/${selected.game.label}/${e.fullFileName}`,
      })
      .then((response) => {
        dispatch(set_record_all_record_state(response.data));
        const team = response.data.Teams.map((data, index) => {
          return { value: index, label: data };
        });
        dispatch(set_record_team_state(team));
      })
      .catch((error) => console.log(error));
  };

  const fetchVideoFolderState = (e) => {
    axios
      .post("http://wdragons.dscloud.me:5010/api/v1/videos", {
        video: `${selected.year.label}/${selected.game.label}/${e.label}`,
      })
      .then((response) => {
        dispatch(set_selected_video_folder_state(response.data));
      })
      .catch((error) => console.log(error));
  };

  const handleChange = (e) => {
    dispatch(set_record_team_state([]));
    // dispatch(set_record_type_state([]));
    dispatch(set_record_player_state([]));
    dispatch(set_record_player_record_state([]));

    dispatch(set_selected_team_state(null));
    dispatch(set_selected_type_state(null));
    dispatch(set_selected_player_state(null));
    dispatch(set_selected_player_record_state(null));
    dispatch(set_selected_date_state(e));
    fetchAllRecord(e);
    fetchVideoFolderState(e);
  };

  return (
    <Box m={0.5}>
      <Select
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: "gray",
          },
        })}
        onChange={handleChange}
        // isLoading={record.date === []}
        styles={customStyles}
        value={selected.date}
        options={record.date}
        placeholder="選擇日期"
      />
    </Box>
  );
}

export default SelectDate;
