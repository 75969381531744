import * as React from "react";
import { useAppSelector } from "../app/hooks";

import AppBar from "@mui/material/AppBar";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import ＳelectYear from "../components/select/selectYear";
import SelectGame from "../components/select/selectGame";
import SelectDate from "../components/select/selectDate";
import SelectTeam from "../components/select/selectTeam";
import SelectType from "../components/select/selectType";
import SelectPlayer from "../components/select/selectPlayer";
import SelectRecord from "../components/select/selectRecord";

import Switcher from "../components/switcher/themeModeSwitcher";

import AutoPlaySwitcher from "../components/switcher/autoPlaySwitcher";
import CamViewSwitcher from "../components/switcher/camViewSwitcher";
import KzoneViewSwitcher from "../components/switcher/kzoneViewSwitcher";
import RecordSwitcher from "../components/switcher/recordSwitcher";

import VideoPlayer from "../components/player/videoPlayer";
import RecordTable from "../components/table/recordTable";

interface Props {
  window?: () => Window;
}

const drawerWidth = 240;
const logoUrl = "/logo_d.png";

const navItems = [
  { heading: "戰力分析", url: "/" },
  { heading: "影像數據", url: "/video_module" },
  { heading: "登出", url: "/logout" },
];

const VideoPlayerPageStyle = {
  mx: "auto",
  width: "auto",
  p: 1,
  m: 1,
  border: "1px solid",
  borderColor: "grey.300",
  borderRadius: 2,
  textAlign: "center",
  fontSize: "1rem",
  fontWeight: "500",
};

export default function VideoPlayerPage(props: Props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const record = useAppSelector((state) => state.videosAppReducer.record);
  const selected = useAppSelector((state) => state.videosAppReducer.selected);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const [value, setValue] = React.useState("1");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        <img alt={"Dragons"} width={"80%"} src={logoUrl} />
      </Typography>
      <Divider />
      <List>
        {navItems.map((item, index) => (
          <ListItem key={index} disablePadding>
            <ListItemButton sx={{ textAlign: "center" }}>
              <Link href={item.url} underline="none" color="inherit">
                <ListItemText primary={item.heading} />
              </Link>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box>
      <AppBar component="nav" sx={{ bgcolor: "#d32f2f" }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
          >
            <img alt={"Dragons"} width={"10%"} src={logoUrl} />
          </Typography>
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            {navItems.map((item, index) => (
              <Button key={index} sx={{ color: "#fff" }}>
                <Link href={item.url} underline="none" color="#fff">
                  {item.heading}
                </Link>
              </Button>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <Box component="main" sx={{ p: 3 }}>
        <Toolbar />
        <Box sx={VideoPlayerPageStyle}>
          <Grid spacing={1} container>
            {selected.palyer_record === null ? (
              <Grid item xs={12} sm={12} md={12}>
                <Box>
                  <ＳelectYear />
                  <SelectGame />
                  <SelectDate />
                  <SelectTeam />
                  <SelectType />
                  <SelectPlayer />
                  <SelectRecord />
                </Box>
              </Grid>
            ) : (
              <>
                <Grid item xs={12} sm={9} md={8}>
                  {record.video_list.length !== 0 && (
                    <VideoPlayer
                      current_video={selected.current_video}
                      auto_play={selected.auto_play}
                      video_list={record.video_list}
                    />
                  )}
                  <RecordTable />
                </Grid>
                <Grid item xs={12} sm={3} md={4}>
                  <Box>
                    <ＳelectYear />
                    <SelectGame />
                    <SelectDate />
                    <SelectTeam />
                    <SelectType />
                    <SelectPlayer />
                    <SelectRecord />
                    <>
                      <RecordSwitcher />
                      <Grid px={1} spacing={1} container>
                        <Grid item xs={12} sm={12} md={6}>
                          <AutoPlaySwitcher />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                          <Switcher />
                        </Grid>
                      </Grid>
                    </>

                    <Box sx={{ width: "100%", typography: "body1" }}>
                      <TabContext value={value}>
                        <Box
                          sx={{
                            borderBottom: 1,
                            borderColor: "bgcolor: 'background.paper'",
                          }}
                        >
                          <TabList
                            textColor="inherit"
                            onChange={handleChange}
                            centered
                          >
                            <Tab label="錄影視角" value="1" />
                            <Tab label="高速視角" value="2" />
                          </TabList>
                        </Box>
                        <TabPanel value="1">
                          <CamViewSwitcher />
                        </TabPanel>
                        <TabPanel value="2">
                          <KzoneViewSwitcher />
                        </TabPanel>
                      </TabContext>
                    </Box>
                  </Box>
                </Grid>
              </>
            )}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}
