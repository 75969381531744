import Box from "@mui/material/Box";

import {
  Experimental_CssVarsProvider as CssVarsProvider,
  useColorScheme,
} from "@mui/material/styles";

import { useEffect, useState } from "react";
import Button from "@mui/material/Button";

const ModeSwitcher = () => {
  const { mode, setMode } = useColorScheme();
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted) {
    return null;
  }

  return (
    <Button
      fullWidth
      size="small"
      variant="outlined"
      color="inherit"
      onClick={() => {
        if (mode === "light") {
          setMode("dark");
        } else {
          setMode("light");
        }
      }}
    >
      {mode === "light" ? "深色模式" : "淺色模式"}
    </Button>
  );
};

function ThemeModeSwitcher() {
  return (
    <CssVarsProvider>
      <Box>
        <ModeSwitcher />
      </Box>
    </CssVarsProvider>
  );
}

export default ThemeModeSwitcher;
