import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { set_record_video_list_state } from "../../slices/videosApp";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

function CamViewSwitcher() {
  const dispatch = useAppDispatch();
  // const record = useAppSelector((state) => state.videosAppReducer.record);
  const selected = useAppSelector((state) => state.videosAppReducer.selected);

  const handleVideoList = (file_path) => {
    const endpoint = "wdragons.dscloud.me:5010/api/v1/video_url_handler/";
    const videoList = selected.palyer_record.video.map((part_filename) => {
      return { url: `http://${endpoint}${file_path}${part_filename}` };
    });
    dispatch(set_record_video_list_state(videoList));
  };

  const handleCam1 = () => {
    const file_path = `${selected.year.label}/${selected.game.label}/${selected.date.label}/Cam-1/${selected.team.label}/${selected.player.label}/`;
    handleVideoList(file_path);
  };

  const handleCam2 = () => {
    const file_path = `${selected.year.label}/${selected.game.label}/${selected.date.label}/Cam-2/${selected.team.label}/${selected.player.label}/`;
    handleVideoList(file_path);
  };

  const handleCam3 = () => {
    const file_path = `${selected.year.label}/${selected.game.label}/${selected.date.label}/Cam-3/${selected.team.label}/${selected.player.label}/`;
    handleVideoList(file_path);
  };

  const handleCam4 = () => {
    const file_path = `${selected.year.label}/${selected.game.label}/${selected.date.label}/Cam-4/${selected.team.label}/${selected.player.label}/`;
    handleVideoList(file_path);
  };

  const handleCam5 = () => {
    const file_path = `${selected.year.label}/${selected.game.label}/${selected.date.label}/Cam-5/${selected.team.label}/${selected.player.label}/`;
    handleVideoList(file_path);
  };

  const handleCam6 = () => {
    const file_path = `${selected.year.label}/${selected.game.label}/${selected.date.label}/Cam-6/${selected.team.label}/${selected.player.label}/`;
    handleVideoList(file_path);
  };

  const handleCam7 = () => {
    const file_path = `${selected.year.label}/${selected.game.label}/${selected.date.label}/Cam-7/${selected.team.label}/${selected.player.label}/`;
    handleVideoList(file_path);
  };

  const handleCam8 = () => {
    const file_path = `${selected.year.label}/${selected.game.label}/${selected.date.label}/Cam-8/${selected.team.label}/${selected.player.label}/`;
    handleVideoList(file_path);
  };

  const handleCam9 = () => {
    const file_path = `${selected.year.label}/${selected.game.label}/${selected.date.label}/Cam-9/${selected.team.label}/${selected.player.label}/`;
    handleVideoList(file_path);
  };

  const handleCam10 = () => {
    const file_path = `${selected.year.label}/${selected.game.label}/${selected.date.label}/Cam-10/${selected.team.label}/${selected.player.label}/`;
    handleVideoList(file_path);
  };

  return (
    <Box m={0.5}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={6}>
          <Button
            onClick={handleCam1}
            disabled={selected.video_folder_state[0].Count === "0"}
            size="large"
            fullWidth
            variant="outlined"
            color="inherit"
          >
            轉播視角
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Button
            onClick={handleCam2}
            disabled={selected.video_folder_state[1].Count === "0"}
            size="large"
            fullWidth
            variant="outlined"
            color="inherit"
          >
            全場視角
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Button
            onClick={handleCam3}
            disabled={selected.video_folder_state[2].Count === "0"}
            size="large"
            fullWidth
            variant="outlined"
            color="inherit"
          >
            左投
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Button
            onClick={handleCam4}
            disabled={selected.video_folder_state[3].Count === "0"}
            size="large"
            fullWidth
            variant="outlined"
            color="inherit"
          >
            右投
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Button
            onClick={handleCam5}
            disabled={selected.video_folder_state[4].Count === "0"}
            size="large"
            fullWidth
            variant="outlined"
            color="inherit"
          >
            左打
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Button
            onClick={handleCam6}
            disabled={selected.video_folder_state[5].Count === "0"}
            size="large"
            fullWidth
            variant="outlined"
            color="inherit"
          >
            右打
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Button
            onClick={handleCam7}
            disabled={selected.video_folder_state[6].Count === "0"}
            size="large"
            fullWidth
            variant="outlined"
            color="inherit"
          >
            視角 1
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Button
            onClick={handleCam8}
            disabled={selected.video_folder_state[7].Count === "0"}
            size="large"
            fullWidth
            variant="outlined"
            color="inherit"
          >
            視角 2
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Button
            onClick={handleCam9}
            disabled={selected.video_folder_state[8].Count === "0"}
            size="large"
            fullWidth
            variant="outlined"
            color="inherit"
          >
            視角 3
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Button
            onClick={handleCam10}
            disabled={selected.video_folder_state[9].Count === "0"}
            size="large"
            fullWidth
            variant="outlined"
            color="inherit"
          >
            視角 4
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

export default CamViewSwitcher;
