import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { set_selected_auto_play_state } from "../../slices/videosApp";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

function AutoPlaySwitcher() {
  const dispatch = useAppDispatch();
  const selected = useAppSelector((state) => state.videosAppReducer.selected);

  return (
    <Box>
      {selected.auto_play === true ? (
        <Button
          onClick={() => {
            dispatch(set_selected_auto_play_state(false));
          }}
          fullWidth
          size="small"
          variant="outlined"
          color="inherit"
        >
          關閉自動播放
        </Button>
      ) : (
        <Button
          onClick={() => {
            dispatch(set_selected_auto_play_state(true));
          }}
          fullWidth
          size="small"
          variant="outlined"
          color="inherit"
        >
          開啟自動播放
        </Button>
      )}
    </Box>
  );
}

export default AutoPlaySwitcher;
