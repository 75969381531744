import { useAppSelector } from "../../app/hooks";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

function RecordTable() {
  const selected = useAppSelector((state) => state.videosAppReducer.selected);
  return (
    <Box>
      <Box m={1}>
        {selected.palyer_record && (
          <TableContainer component={Paper}>
            <Table sx={{ width: "100%" }} size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="center">局數</TableCell>
                  <TableCell align="center">投手</TableCell>
                  <TableCell align="center">打者</TableCell>
                  <TableCell align="center">球種</TableCell>
                  <TableCell align="center">球速</TableCell>
                  <TableCell align="center">累計</TableCell>
                  <TableCell align="center">狀態</TableCell>
                  <TableCell align="center">結果</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="center">
                    <b>{selected.palyer_record.record.局數}</b>
                  </TableCell>
                  <TableCell align="center">
                    <b>{selected.palyer_record.record.投手}</b>
                  </TableCell>
                  <TableCell align="center">
                    <b>{selected.palyer_record.record.打者}</b>
                  </TableCell>
                  <TableCell align="center">
                    <b>{selected.palyer_record.record.球種}</b>
                  </TableCell>
                  <TableCell align="center">
                    <b>{selected.palyer_record.record.球速} km/h</b>
                  </TableCell>
                  <TableCell align="center">
                    <b>{selected.palyer_record.record.Count}</b>
                  </TableCell>
                  <TableCell align="center">
                    <b>{selected.palyer_record.record.在壘與出局}</b>
                  </TableCell>
                  <TableCell align="center">
                    <b>{selected.palyer_record.record.結果}</b>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
      <Box m={1}>
        {selected.palyer_record && (
          <TableContainer component={Paper}>
            <Table sx={{ width: "100%" }} size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="center">出棒狀態</TableCell>
                  <TableCell align="center">擊球初速</TableCell>
                  <TableCell align="center">擊球仰角</TableCell>
                  <TableCell align="center">擊球方向</TableCell>
                  <TableCell align="center">隊總用球數</TableCell>
                  <TableCell align="center">投手總球數</TableCell>
                  <TableCell align="center">轉軸</TableCell>
                  <TableCell align="center">轉速</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="center">
                    <b>{selected.palyer_record.record.揮棒}</b>
                  </TableCell>
                  <TableCell align="center">
                    <b>{selected.palyer_record.record.ExitVelo} km/h</b>
                  </TableCell>
                  <TableCell align="center">
                    <b>{selected.palyer_record.record.LaunchAngle} deg</b>
                  </TableCell>
                  <TableCell align="center">
                    <b>{selected.palyer_record.record.Direction} deg</b>
                  </TableCell>
                  <TableCell align="center">
                    <b>{selected.palyer_record.record.總球數}</b>
                  </TableCell>
                  <TableCell align="center">
                    <b>{selected.palyer_record.record.投手球數}</b>
                  </TableCell>
                  <TableCell align="center">
                    <b>h:m</b>
                  </TableCell>
                  <TableCell align="center">
                    <b>rpm</b>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
    </Box>
  );
}

export default RecordTable;
