import { createSlice } from "@reduxjs/toolkit";

interface Year {
  value: number;
  label: string;
}

interface Game {
  value: number;
  label: string;
}

interface Date {
  value: number;
  label: string;
  fullFileName: string;
}

interface Team {
  value: number;
  label: string;
}

interface Type {
  value: number;
  label: string;
}

interface Player {
  value: number;
  label: string;
}

interface PlayerRecord {
  value: number;
  label: string;
  record: any;
  all: any;
}

interface VideoList {
  url: string;
}

interface InitialState {
  record: {
    year: Array<Year>;
    game: Array<Game>;
    date: Array<Date>;
    team: Array<Team>;
    type: Array<Type>;
    player: Array<Player>;
    palyer_record: Array<PlayerRecord>;
    video_list: Array<VideoList>;
    all_record: any;
  };
  selected: {
    year: any;
    game: any;
    date: any;
    team: any;
    type: any;
    player: any;
    palyer_record: any;
    video_folder_state: any;
    current_video: any;
    auto_play: boolean;
  };
}

// 定義初始值
const initialState: InitialState = {
  record: {
    year: [],
    game: [],
    date: [],
    team: [],
    type: [
      { value: 0, label: "投手" },
      { value: 1, label: "打者" },
    ],
    player: [],
    palyer_record: [],
    video_list: [],
    all_record: null,
  },
  selected: {
    year: null,
    game: null,
    date: null,
    team: null,
    type: null,
    player: null,
    palyer_record: null,
    video_folder_state: null,
    current_video: null,
    auto_play: true,
  },
};

export const videosAppSlice = createSlice({
  name: "videosApp",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // 1. state ->  當下的狀態
    // 2. action -> 對應的動作

    // record
    set_record_year_state: (state, action) => {
      state.record.year = action.payload;
    },
    set_record_game_state: (state, action) => {
      state.record.game = action.payload;
    },
    set_record_date_state: (state, action) => {
      state.record.date = action.payload;
    },
    set_record_team_state: (state, action) => {
      state.record.team = action.payload;
    },
    set_record_type_state: (state, action) => {
      state.record.type = action.payload;
    },
    set_record_player_state: (state, action) => {
      state.record.player = action.payload;
    },
    set_record_player_record_state: (state, action) => {
      state.record.palyer_record = action.payload;
    },
    set_record_video_list_state: (state, action) => {
      state.record.video_list = action.payload;
    },
    // all
    set_record_all_record_state: (state, action) => {
      state.record.all_record = action.payload;
    },
    // selected
    set_selected_year_state: (state, action) => {
      state.selected.year = action.payload;
    },
    set_selected_game_state: (state, action) => {
      state.selected.game = action.payload;
    },
    set_selected_date_state: (state, action) => {
      state.selected.date = action.payload;
    },
    set_selected_team_state: (state, action) => {
      state.selected.team = action.payload;
    },
    set_selected_type_state: (state, action) => {
      state.selected.type = action.payload;
    },
    set_selected_player_state: (state, action) => {
      state.selected.player = action.payload;
    },
    set_selected_player_record_state: (state, action) => {
      state.selected.palyer_record = action.payload;
    },
    set_selected_video_folder_state: (state, action) => {
      state.selected.video_folder_state = action.payload;
    },
    set_selected_current_video_state: (state, action) => {
      state.selected.current_video = action.payload;
    },
    set_selected_auto_play_state: (state, action) => {
      state.selected.auto_play = action.payload;
    },
  },
});

export const {
  // record
  set_record_year_state,
  set_record_game_state,
  set_record_date_state,
  set_record_team_state,
  set_record_type_state,
  set_record_player_state,
  set_record_player_record_state,
  set_record_video_list_state,
  // all
  set_record_all_record_state,
  // selected
  set_selected_year_state,
  set_selected_game_state,
  set_selected_date_state,
  set_selected_team_state,
  set_selected_type_state,
  set_selected_player_state,
  set_selected_player_record_state,
  set_selected_video_folder_state,
  set_selected_current_video_state,
  set_selected_auto_play_state,
} = videosAppSlice.actions;

export default videosAppSlice.reducer;
