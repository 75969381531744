import { useAppSelector, useAppDispatch } from "../../app/hooks";
import {
  // set_record_type_state,
  set_record_player_state,
  set_record_player_record_state,
  set_selected_team_state,
  set_selected_player_state,
  set_selected_type_state,
  set_selected_player_record_state,
} from "../../slices/videosApp";

import Box from "@mui/material/Box";
import Select from "react-select";

function SelectTeam() {
  const dispatch = useAppDispatch();
  const record = useAppSelector((state) => state.videosAppReducer.record);
  const selected = useAppSelector((state) => state.videosAppReducer.selected);

  const customStyles = {
    option: (provided) => ({
      ...provided,
      color: "black",
    }),
  };

  const handleChange = (e) => {
    // dispatch(set_record_type_state([]));
    dispatch(set_record_player_state([]));
    dispatch(set_record_player_record_state([]));

    dispatch(set_selected_type_state(null));
    dispatch(set_selected_player_state(null));
    dispatch(set_selected_player_record_state(null));
    dispatch(set_selected_team_state(e));
  };

  return (
    <Box m={0.5}>
      <Select
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: "gray",
          },
        })}
        onChange={handleChange}
        // isLoading={record.team === []}
        styles={customStyles}
        value={selected.team}
        options={record.team}
        placeholder="選擇球隊"
      />
    </Box>
  );
}

export default SelectTeam;
