import { useEffect, useState } from "react";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import {
  set_selected_current_video_state,
  set_selected_player_record_state,
} from "../../slices/videosApp";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

function RecordSwitcher() {
  const dispatch = useAppDispatch();
  const record = useAppSelector((state) => state.videosAppReducer.record);
  const selected = useAppSelector((state) => state.videosAppReducer.selected);

  const [addBtnDisabledState, setAddBtnDisabledState] = useState(false);
  const [subBtnDisabledState, setSubBtnDisabledState] = useState(false);

  const videoTotalIndex = record.video_list.length;
  const currentVideoIndex = selected.current_video;

  useEffect(() => {
    handelCheckIndex(videoTotalIndex, currentVideoIndex);
  }, [videoTotalIndex, currentVideoIndex]);

  const handelCheckIndex = (videoTotalIndex, currentVideoIndex) => {
    if (videoTotalIndex - 1 !== currentVideoIndex && videoTotalIndex !== 0) {
      setAddBtnDisabledState(false);
    } else {
      setAddBtnDisabledState(true);
    }

    if (currentVideoIndex !== 0 && videoTotalIndex !== 0) {
      setSubBtnDisabledState(false);
    } else {
      setSubBtnDisabledState(true);
    }
  };

  const handelAddIndex = () => {
    if (videoTotalIndex - 1 !== currentVideoIndex && videoTotalIndex !== 0) {
      dispatch(set_selected_current_video_state(currentVideoIndex + 1));
      dispatch(
        set_selected_player_record_state(
          record.palyer_record[currentVideoIndex + 1]
        )
      );
    }
  };

  const handelSubIndex = () => {
    if (currentVideoIndex !== 0 && videoTotalIndex !== 0) {
      dispatch(set_selected_current_video_state(currentVideoIndex - 1));
      dispatch(
        set_selected_player_record_state(
          record.palyer_record[currentVideoIndex - 1]
        )
      );
    }
  };

  return (
    <Box m={1}>
      <Grid container spacing={1}>
        <Grid item xs={6} sm={6} md={6}>
          <Button
            onClick={handelSubIndex}
            disabled={subBtnDisabledState}
            fullWidth
            size="large"
            variant="outlined"
            color="inherit"
          >
            上一筆
          </Button>
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          <Button
            onClick={handelAddIndex}
            disabled={addBtnDisabledState}
            fullWidth
            size="large"
            variant="outlined"
            color="inherit"
          >
            下一筆
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

export default RecordSwitcher;
