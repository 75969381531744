import { useAppSelector, useAppDispatch } from "../../app/hooks";
import {
  set_selected_player_state,
  set_selected_player_record_state,
  set_record_player_record_state,
  set_record_video_list_state,
} from "../../slices/videosApp";

import Box from "@mui/material/Box";
import Select from "react-select";

function SelectPlayer() {
  const dispatch = useAppDispatch();
  const record = useAppSelector((state) => state.videosAppReducer.record);
  const selected = useAppSelector((state) => state.videosAppReducer.selected);

  const customStyles = {
    option: (provided) => ({
      ...provided,
      color: "black",
    }),
  };

  const playerRecordSelectOptions = (e) => {
    if (record.all_record[selected.team.label] === "Guest") {
      switch (selected.type.label) {
        case "投手":
          const selectPitcherPlayerRecord =
            record.all_record.HomeAllLogs.filter(
              (player) => player.投手 === e.label
            );
          const guestPitcherVideoNameList = [];
          const guestPitcherRecord = selectPitcherPlayerRecord.map(
            (data, index) => {
              guestPitcherVideoNameList.push(
                `第${data.投手球數}球-${data.投手}vs${data.打者}-${data.Count}-${data.球種}-${data.結果}`
              );
              return {
                value: index,
                label: `第${data.局數}局 ${data.好壞球} ${data.球種} ${data.球速} km/h`,
                record: data, // now selected record.
                all: selectPitcherPlayerRecord, // this player all record in the selected game.
                video: guestPitcherVideoNameList,
              };
            }
          );
          dispatch(set_record_player_record_state(guestPitcherRecord));
          break;
        case "打者":
          const selectBatterPlayerRecord =
            record.all_record.GuestAllLogs.filter(
              (player) => player.打者 === e.label
            );
          const guestBatterVideoNameList = [];
          const guestBatterRecord = selectBatterPlayerRecord.map(
            (data, index) => {
              guestBatterVideoNameList.push(
                `第${data.投手球數}球-${data.投手}vs${data.打者}-${data.Count}-${data.球種}-${data.結果}`
              );
              return {
                value: index,
                label: `第${data.局數}局 ${data.好壞球} ${data.結果} ${data.Count}`,
                record: data,
                all: selectBatterPlayerRecord,
                video: guestBatterVideoNameList,
              };
            }
          );
          dispatch(set_record_player_record_state(guestBatterRecord));
          break;
        default:
          break;
      }
    } else {
      switch (selected.type.label) {
        case "投手":
          const selectPitcherPlayerRecord =
            record.all_record.GuestAllLogs.filter(
              (player) => player.投手 === e.label
            );
          const homePitcherVideoNameList = [];
          const homePitcherRecord = selectPitcherPlayerRecord.map(
            (data, index) => {
              homePitcherVideoNameList.push(
                `第${data.投手球數}球-${data.投手}vs${data.打者}-${data.Count}-${data.球種}-${data.結果}`
              );
              return {
                value: index,
                label: `第${data.局數}局 ${data.好壞球} ${data.球種} ${data.球速} km/h`,
                record: data,
                all: selectPitcherPlayerRecord,
                video: homePitcherVideoNameList,
              };
            }
          );
          dispatch(set_record_player_record_state(homePitcherRecord));
          break;
        case "打者":
          const selectBatterPlayerRecord = record.all_record.HomeAllLogs.filter(
            (player) => player.打者 === e.label
          );
          const homeBatterVideoNameList = [];
          const homeBatterRecord = selectBatterPlayerRecord.map(
            (data, index) => {
              homeBatterVideoNameList.push(
                `第${data.投手球數}球-${data.投手}vs${data.打者}-${data.Count}-${data.球種}-${data.結果}`
              );
              return {
                value: index,
                label: `第${data.局數}局 ${data.好壞球} ${data.結果} ${data.Count}`,
                record: data,
                all: selectBatterPlayerRecord,
                video: homeBatterVideoNameList,
              };
            }
          );
          dispatch(set_record_player_record_state(homeBatterRecord));
          break;
        default:
          break;
      }
    }
  };

  const handleChange = (e) => {
    dispatch(set_record_player_record_state([]));
    dispatch(set_record_video_list_state([]));

    dispatch(set_selected_player_record_state(null));
    dispatch(set_selected_player_state(e));
    playerRecordSelectOptions(e);
  };

  return (
    <Box m={0.5}>
      <Select
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: "gray",
          },
        })}
        onChange={handleChange}
        // isLoading={record.player === []}
        styles={customStyles}
        value={selected.player}
        options={record.player}
        placeholder="選擇球員"
      />
    </Box>
  );
}

export default SelectPlayer;
