import { useAppSelector, useAppDispatch } from "../../app/hooks";
import {
  set_selected_current_video_state,
  set_selected_player_record_state,
} from "../../slices/videosApp";

import Box from "@mui/material/Box";
import Select from "react-select";

function SelectRecord() {
  const dispatch = useAppDispatch();
  const record = useAppSelector((state) => state.videosAppReducer.record);
  const selected = useAppSelector((state) => state.videosAppReducer.selected);

  const customStyles = {
    option: (provided) => ({
      ...provided,
      color: "black",
    }),
  };

  const handleChange = (e) => {
    dispatch(set_selected_player_record_state(e));
    dispatch(set_selected_current_video_state(e.value));
  };

  return (
    <Box m={0.5}>
      <Select
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: "gray",
          },
        })}
        onChange={handleChange}
        // isLoading={record.palyer_record === []}
        styles={customStyles}
        value={selected.palyer_record}
        options={record.palyer_record}
        placeholder="選擇紀錄"
      />
    </Box>
  );
}

export default SelectRecord;
