import type { RouteObject } from "react-router-dom";

import VideoPlayer from "./pages/videoPlayer";

const routes: RouteObject[] = [
  {
    path: "*",
    element: <VideoPlayer />,
    children: [],
  },
];

export default routes;
