import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { createRef, useState } from "react";

import { Playlist } from "reactjs-video-playlist-player";

import {
  set_selected_current_video_state,
  set_selected_player_record_state,
} from "../../slices/videosApp";

function VideoPlayer(props) {
  const dispatch = useAppDispatch();
  const record = useAppSelector((state) => state.videosAppReducer.record);

  const vidRef = createRef(null);
  const currentVideoIndex = props.current_video;
  const [isIndexOnZero, setIsIndexOnZero] = useState(null);

  const setCurrentVideo = (e) => {
    if (e === 0 && isIndexOnZero !== 0) {
      setIsIndexOnZero(e);
    }

    if (e !== 0) {
      dispatch(set_selected_current_video_state(e));
      dispatch(set_selected_player_record_state(record.palyer_record[e]));
    } else if (currentVideoIndex !== 0) {
      dispatch(set_selected_current_video_state(currentVideoIndex));
    }

    if (e === 0 && isIndexOnZero === 0) {
      dispatch(set_selected_current_video_state(e));
      dispatch(set_selected_player_record_state(record.palyer_record[e]));
    }
  };

  const params = {
    videos: props.video_list,
    vidRef: vidRef,
    autoPlay: props.auto_play,
    showQueue: true,
    playForward: true,
    currentVideo: props.current_video,
    setCurrentVideo: setCurrentVideo,
    defaultQueueItemPlaceholderThumbnail: "/logo512.png",
  };

  return <Playlist playlistParams={params} />;
}

export default VideoPlayer;
